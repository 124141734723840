import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
    courses: [],
    courses_payment: []
};

const getters = {
    ITEMS_COURSES(state) {
        return state.courses;
    },
    ITEM_COURSES_PAYMENT(state) {
        return state.courses_payment;
    },
};

const mutations = {
    SET_ITEM_COURSES(state, payload) {
        state.courses = payload;
    },

    SET_ITEM_COURSES_PAYMENT(state, payload) {
        state.courses_payment = payload;
    },
};

const actions = {
    // async INDEX( {commit} ) {
    //     try {
    //         const response = await Api.get(`/cursos`);
    //         console.log('cursos', response.data.cursos)
    //         commit("SET_ITEM_COURSES", response.data.cursos);
    //         // commit("section/SET_ITEM_SECTION", response.data.cursos.sections, {root:true});
    //         return response.data;
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },
    async SHOW(_ , payload ) {
        try {
            const response = await Api.get(`/cursos/${payload.area}/${payload.area_id}/${payload.slug}`);            
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async PREVIEW(_, payload){
        try {

            const response = await Api.get(`/cursos/preview/${payload.area}/${payload.area_id}/${payload.slug}/${payload.preview}`);            
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // async EVALUATIONS(_ , payload) {
    //     try {
    //         const response = await Api.get(`/evaluacion/${payload.id_user}/${payload.course_id}`);            
    //         return response.data;
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },
    // async PAGOS(_ , payload) {
    //     try {
    //         const response = await Api.post('/pago/',payload);            
    //         return response.data;
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },
    // async COURSE_PAYMENT({commit} , payload) {
    //     try {
    //         const response = await Api.get(`homeuser/${payload.id_user}`);  
    //         console.log('payment', response.data.cursos)
    //         commit("SET_ITEM_COURSES_PAYMENT", response.data.cursos);          
    //         return response.data;
    //     } catch (error) {
    //         return Promise.reject(error);
    //     }
    // },
};

export const course = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
