<template>
 
  <div id="inspire">
    <!-- PREHEADER -->
    <div class="" id="pre-header">
      <div class="container-fluid | d-flex justify-space-between flex-center | mx-0 | px-4 py-2">
        <div class="mx-3">
        <router-link to="/">
        <v-img  
          v-if="$route.matched.some(({ name }) => name !== 'player')"
          alt="Logo IDIP"
          class="shrink mr-2"
          contain
          :src="logo ? URL_IMG+logo :'../../assets/img/Logo_Idip_negro.svg'"
          transition="scale-transition"
          width="80"
        />
        <v-img
          v-else
          alt="Logo IDIP"
          class="shrink mr-2"
          contain
          :src="'../../assets/img/Logo_idip_blanco.svg'"
          transition="scale-transition"
          width="80"
        />
        </router-link>

      </div>
        <div class="d-flex flex-column flex-sm-row align-sm-center">

          <a :href="i.link" v-for="(i, key) in numbers" :key="key" class="link-dark | fs-14 | ml-5 me-5 | d-flex flex-center align-center">
            <img :src="require(`../../assets/img/${i.icon}`)" alt="i.name" class="me-1" width="18">
            {{ i.numberDisplay }}
          </a>

          <router-link class="link-dark"  to="/login"><v-btn
        v-if="!Is_Authenticated"
        class="text-capitalize bold font-size-auth d-none me-3 d-md-flex d-lg-flex d-xl-flex"
        text
      >Iniciar Sesión</v-btn></router-link>

      <router-link class="link-light"  to="/register"><v-btn
        v-if="!Is_Authenticated"
        class="text-capitalize elevation-0 bold font-size-auth d-none d-md-flex d-lg-flex d-xl-flex"
        color="#5ebd99"
        dark
      >Registrate</v-btn></router-link>
     <router-link class="link-light"  to="/user/home">
     <v-btn
        v-if="Is_Authenticated"
        class="text-capitalize me-3 elevation-0 bold font-size-auth d-none d-md-flex d-lg-flex d-xl-flex"
        color="#5ebd99"
        dark
      >Mis Cursos</v-btn></router-link>
      <v-btn
        v-if="Is_Authenticated"
        class="text-capitalize elevation-0 bold font-size-auth d-none d-md-flex d-lg-flex d-xl-flex"
        color="#3D3D3D"
        dark
        @click="logout"
      >Salir</v-btn>
                
        </div>
      </div>
    </div>
    <!-- /PREHEADER -->
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp"  app right  disable-resize-watcher>
      <v-list dense>
        <template>
          <v-list-item >
            <v-list-item-action>
              <v-icon>fas fa-home</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title><router-link class="link-dark" to="/">Inicio</router-link></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group append-icon  v-for="(item, key) in areas" :key="key">
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon>fas fa-chevron-down</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >{{ item.description }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(submenu, ky) in item.subareas" :key="ky" link>
              <v-list-item-content >
                <v-list-item-title>
                  <router-link class="link-dark" :to="`/area/${item.slug}/${submenu.slug}`">{{ submenu.description }}</router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item >
            <v-list-item-action>
              <v-icon>fas fa-hands-helping</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>
                 <router-link class="link-dark" to="/benefit">Beneficios</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-action>
              <v-icon>fas fa-pencil-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>
                 <router-link class="link-dark" to="/inscripcion">Inscripción</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-action>
              <v-icon>fas fa-phone-volume</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>
                 <router-link class="link-dark" to="/contact">Contacto</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-list-item >
            <v-list-item-action>
              <v-icon>fas fa-map-pin</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>
                 <router-link class="link-dark" to="/ubication">¿Dónde estamos?</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-action>
              <v-icon>far fa-question-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>
                 <router-link class="link-dark" to="/frequent-questions">Preguntas frecuentes</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="mt-4" v-if="!Is_Authenticated">
            <v-list-item-content>
              <v-list-item-title>
                <router-link class="link-dark"  to="/login">
                  <v-btn
                    v-if="!Is_Authenticated"
                    class=""
                    color="#5ebd99"
                    dark
                  >Iniciar Sesión</v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="" v-if="!Is_Authenticated">
            <v-list-item-content>
              <v-list-item-title>
                <router-link class="link-light"  to="/register">
                  <v-btn
                    
                    class=""
                    color="#5ebd99"
                    dark
                  >Registrate</v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class=""  v-if="Is_Authenticated">
            <v-list-item-content>
              <v-list-item-title>
                <router-link class="link-light"  to="/user/home">
                  <v-btn
                    class=""
                    color="#5ebd99"
                    dark
                  >Mis Cursos</v-btn>
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class=""  v-if="Is_Authenticated">
            <v-list-item-content>
              <v-list-item-title>
                <v-btn
                  color="#3D3D3D"
                  dark
                  @click="logout"
                >Salir</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <div style="position: sticky; top: 10px;">
    <v-app-bar
      :color="
        $route.matched.some(({ name }) => name === 'player') ? 'black' : 'white'
      "
      :dark="
        $route.matched.some(({ name }) => name === 'player') ? true : false
      "
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      flat
      height="80"
    >
      <!-- app -->
      
      <v-toolbar-items id="menu" class="d-none d-md-flex d-lg-flex d-xl-flex">
        <v-btn text class="semi-bold toolbar-hover-inicio px-3" @click="routes('home')">Inicio</v-btn>
      

        <v-menu bottom offset-y v-for="(item, key) in areas" :key="key">
          <template v-slot:activator="{on}">
            <v-btn text class="regular toolbar-hover px-3"
            :class="item.slug"
            @mouseover="hoverColor(item, true, item.slug)"
            @mouseleave="hoverColor(item, false, item.slug)"
            v-on="on">{{ item.description }}</v-btn>
          </template>
          <!--  -->
          <v-list
          :class="'list-'+item.slug"
          >
            <v-list-item v-for="(submenu, ky) in item.subareas" :key="ky">
              <v-list-item-title class="regular" style="cursor:pointer">
                <router-link class="link-dark"

                 :to="`/area/${item.slug}/${submenu.slug}`"><span >{{ submenu.description }}</span></router-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn text class="regular toolbar-hover px-3" @click="benefit">Beneficios</v-btn>

        <v-btn text class="regular toolbar-hover px-3" @click="inscripcion">Inscripción</v-btn>

        <router-link class="link-dark_menu regular text-uppercase align-center d-flex toolbar-hover px-3" to="/contact">Contacto</router-link>
      

        <router-link class="link-dark_menu regular text-center text-uppercase align-center d-flex toolbar-hover px-3" to="/ubication">¿Dónde estamos?</router-link>


        <router-link class="link-dark_menu regular text-center text-uppercase align-center d-flex toolbar-hover px-3" to="/frequent-questions">Preguntas frecuentes</router-link>

        <div v-for="(e, key) in custompagemenu" :key="`0${key}`" class="d-flex" >
          <router-link v-if="e.is_menu_top == 1" class="link-dark_menu h-100 regular text-center text-uppercase align-center d-flex toolbar-hover px-3" :to="`/instituto/${e.slug}`">{{ e.title }}</router-link>
        </div>

      </v-toolbar-items>
      
      <v-spacer></v-spacer>
      
      <v-app-bar-nav-icon class="d-md-none d-lg-none d-xl-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    </div>
  </div>
  
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: "Toolbar",
  data: () => ({
    login_loading: false,
    drawer: false,
  }),
  watch: {
   
  },
  computed: {
    ...mapState('home',['areas','social','custompagemenu']),
    ...mapState(['URL_IMG']),
    ...mapGetters('home',['numbers','logo','gruposMenu']),
    Is_Authenticated() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },
  },
  methods: {
    hoverColor(item, status, element){
      if (status) {
        document.querySelector('.'+element).style.background = item.fondo
        document.querySelector('.'+element).style.color = item.color
      }else{
        document.querySelector('.'+element).style.background = ''
        document.querySelector('.'+element).style.color =  ''
      }
    },
    benefit() {
      this.$router.push({ name: "benefit" });
    },
    inscripcion() {
      this.$router.push({ name: "inscripcion" });
    },
    routes(name) {
      if (name == "login") {
        this.dialog_login = true;
      } else if (name == "register") {
        this.dialog_register = true;
      } else {
        this.$router.push({ name: name });
      }
    },
    logout() {
      localStorage.clear();
      this.$store.commit("auth/CLEAR_DATA");
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style lang="scss">
#menu{
  button, span, a{
    font-size: .85rem;
  }
  
}
#pre-header{
  background: rgba(0,0,0,.05);

}
.link-light{
  text-decoration: none;
  color: #ffffff !important;
}
.link-dark{
  text-decoration: none;
  color: #000000 !important;
}
.link-light:hover, .link-dark:hover{
  text-decoration: underline;
}
.link-dark_menu{
  @extend .link-dark;
      font-size: 0.875rem;
      flex: none;
    letter-spacing: 0.0892857143em;
  &:hover{
    text-decoration: none;
    color: white !important;
  }
}
.toolbar-hover{
  &:hover{
     a{
    color: white !important;
  }
  }
 
}
.fs-12{
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}
.w-100{
  width: 100%;
}

</style>