import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
    users_courses: [],
    estudiante: {},
    curso_select: {},
    dialogEvaluacion: false,
    evaluacion: {},
    actividadesResueltas: [],
    cuponDescuento: {},
    dialogClase: false,
    clase: {},
    otrasUnidades: {},
    salaEspera: [],
    unidad: { id: null },
    mensajes: [],
    foro_unidad: null,
    actividades: [],
    rutaCertificado: null,
    unidades_inscritas: []

};

const getters = {
    items_tabs(state) {
        // var array = []
        var array = [{ name: "Inicio", icon: 'fa-home' }]
            // array.push({name:"Descripción", icon: 'fa-info-circle'})

        if (state.curso_select.area) {
            if (state.curso_select.area.isClases == 1) {
                array.push({ name: "Clases", icon: 'fa-book' })
            }
            if (state.curso_select.area.isDocumentos == 1) {
                array.push({ name: "Documentos", icon: 'fa-folder-open' })
            }
            if (state.curso_select.area.isEvaluaciones == 1) {
                array.push({ name: "Evaluaciones", icon: 'fa-pencil-ruler' })
            }

        }
        array.push({ name: 'Foro', icon: 'fa fa-comments' })
        if (state.curso_select.aprobado) {
            array.push({ name: "Certificado", icon: 'fa-award' })
        }
        return array
    },
    mensajesFilter(state) {
        let foro = state.unidad.foro_unidad.find((element) => element.id == state.foro_unidad)
        if (foro != undefined) {
            return foro.mensajes
        }
        return []
    },
    validaEvaluacion(state) {
        let obj = []
        if (state.curso_select.unidades != undefined) {
            state.curso_select.unidades.forEach((u) => {
                let puntajes = []
                u.actividades.forEach((ua) => {
                    let algo = state.actividadesResueltas.filter((element) => element.unidades_actividades_id == ua.id)
                    if (algo.length > 0) {
                        puntajes.push(algo[0].puntaje)
                    }
                })
                let conteo = 0
                for (var i = 0; i < puntajes.length; i++) {
                    conteo += parseFloat(puntajes[i])
                }
                if (u.puntaje != null) {
                    if (conteo >= u.puntaje) {
                        obj.push({ unidad: u.id, aprobado: true })
                    } else {
                        obj.push({ unidad: u.id, aprobado: false })
                    }
                } else {
                    obj.push({ unidad: u.id, aprobado: false })
                }
            })

            if (obj.length > 0) {
                let num = 0
                obj.filter((element, index) => {
                    if (element.aprobado) {
                        num = index + 1
                    }
                })

                if (obj[num] != undefined) {
                    obj[num].aprobado = true
                }
            }
        }
        return obj
    },
    estudiante_info() {
        return JSON.parse(localStorage.getItem('estudiante'))
    },
    metodosPago(state) {
        if (Object.keys(state.curso_select).length > 0) {
            if (state.curso_select.metodos_pagos != null || state.curso_select.metodos_pagos != '') {
                return JSON.parse(state.curso_select.metodos_pagos)
            }
        }
        return []
    },
};

const mutations = {
    SET_UNIDAD(state, val) {
        state.unidad = val
        state.mensajes = []
        state.mensajes = val.foro_unidad
    },
    SET_USERS_COURSES(state, val) {
        state.users_courses = val;
    },
    SET_USERS_ESTUDIANTE(state, val) {
        state.estudiante = val
        localStorage.setItem("estudiante", JSON.stringify(state.estudiante));
    },
    SET_COURSE_SELECT(state, val) {
        state.curso_select = val
    },
    CHANGE_DIALOG_EVALUACION(state, val) {
        state.dialogEvaluacion = val
    },
    CHANGE_DIALOG_CLASE(state, val) {
        state.dialogClase = val
    },
    SET_CLASE(state, val) {
        state.clase = val
    },
    SET_EVALUACION(state, val) {
        state.evaluacion = val
    },
    SET_ACTIVIDADES(state, val) {
        state.actividades = val
    },
    SET_ACTIVIDADES_RESUELTAS(state, val) {
        state.actividadesResueltas = val
    },
    RESET_ACTIVIDADES_RESUELTAS(state, val) {
        state.actividadesResueltas = val
    },
    SET_CUPON(state, val) {
        state.cuponDescuento = val
    },
    SET_OTRAS_UNIDADES(state, val) {
        state.otrasUnidades = val
    },
    SET_USER_SALAESPERA(state, val) {
        state.salaEspera = val
    },
    SET_MENSAJES(state, val) {
        state.mensajes = val
    },
    SET_FORO(state, val) {
        state.foro_unidad = val
    },
    SET_RUTA_CERTIFICADO(state, val) {
        state.rutaCertificado = val
    },
    SET_UNIDADES_INSCRITAS(state, val){
      state.unidades_inscritas = val
    }
};

const actions = {
    async USER_HOME(context, id) {
        try {
            const response = await Api.get(`auth/user/home/${id}`);
            if (response.data.status) {
                context.commit('SET_USERS_COURSES', response.data.cursos)
                context.commit('SET_USERS_ESTUDIANTE', response.data.estudiante)
            }
            context.commit('SET_USER_SALAESPERA', response.data.salaEspera)
                // return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async COURSE_SELECT(context, data) {
        try {
            const response = await Api.get(`auth/user/course/${data.area}/${data.slug}/${data.user_id}`)
            console.log(response.data)
            context.commit("SET_ACTIVIDADES", response.data.actividades)
            context.commit('SET_COURSE_SELECT', response.data.curso)
            context.commit('SET_ACTIVIDADES_RESUELTAS', response.data.resuelto)
            context.commit('SET_OTRAS_UNIDADES', response.data.otrasUnidades)
            context.commit('SET_RUTA_CERTIFICADO', response.data.curso.rutaCertificado)
            return response.data
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async SEND_ACTIVIDAD(context, data) {
        try {
            const response = await Api.post(`auth/user/course/actividad/${data.id}`, data)
            return response
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async REGISTERUNIDADES(context, val) {
        try {
            const response = await Api.get(`auth/registerunidades/${val.slug}/${val.users_id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async INSCRIPCIONUNIDADES(context, val) {
        try {
          var obj = {
            inscripcion_id : context.state.curso_select.inscripcion,
            unidades : context.state.unidades_inscritas
          }
          // if (this.isMes) {
          //   obj.cuotas = this.cuotasInsctitas.length
          // }
          
          let pago = 'Automatico'
          if (val == 'Efectivo') {
            pago = val
          }
            const response = await Api.post(`auth/inscripcion-unidades?metodo=${pago}`, obj)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async SEND_MENSAJE(context, val) {
        try {
            const response = await Api.post(`auth/user/mensaje/new`, val)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async GENERATE_CERTIFICADO(context, val) {
        try {
            if (!context.getters.estudiante_info) {
                this.$router.push(`usercourse`)
            }
            let obj = {
                cursos_id: context.state.curso_select.id,
                estudiantes_id: context.getters.estudiante_info.id,
                users_id: val.users_id.id,
                inscripcion_id: context.state.curso_select.inscripcion
            }
            console.log(obj)
            const response = await Api.post(`auth/user/certificado/generate`, obj)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
};

export const users = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};