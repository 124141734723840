<template>
  <v-app>
    <loading 
      :active.sync="isLoading" 
      :can-cancel="true" 
      :is-full-page="true"
      :opacity="1"
      :width="200"

      :z-index="99999999"
      :color="'#5ebd99'"
    ></loading>


    <v-main>
      <Toolbar />
      <v-container fluid class="py-0">
        <router-view />
      </v-container>
    </v-main>

    <Footer />
    
    <div v-if="cupones && cupones.length > 0" class="popover"> 
      <div v-for="(i, key) in cupones" :key="key"   :id="`cupon${i.id}`">
        <transition name="fade" mode="out-in">
          <v-sheet
            :rounded="true"
            class="mb-3"
            color="white"
            elevation="10"
            height="200"
            width="300"
            v-if="!cuponesArr.includes(i.id)"
            :style="{backgroundImage: `url('${URL_IMG}${i.mensaje}')`, backgroundSize: 'countain'}"
          >
            <div class="d-flex justify-end px-3 py-3 times-cupon" style="">
              <i class="fa fa-times" @click="cerrarCupones(i.id)"></i>
            </div>
          </v-sheet>
        </transition>  
      </div>
    </div>
 
  </v-app>
</template>

<script>
import Toolbar from "./components/Layout/Toolbar";
import Footer from "./components/Layout/Footer";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from 'vuex'

export default {
  name: "App",
  components: {
    Toolbar,
    Footer,
    Loading
  },
  data: () => ({
    cuponesArr: []
  }),
  created(){
    this.home()
    if (this.$cookies.isKey('cupones')) {
      this.cuponesArr = JSON.parse(this.$cookies.get('cupones'))
    }

  },
  computed:{
    ...mapState('home',['cupones','isLoading']),
    ...mapState(['URL_IMG'])
  },
  methods: {
    async home() {
      try {
       await this.$store.dispatch("home/GET");
      } catch (e) {
        // statements
        console.log(e);
      }
    },
    cerrarCupones(id){
      this.cuponesArr.push(id)
      this.$cookies.set('cupones', JSON.stringify(this.cuponesArr))
    }
  },
};
</script>
<style lang="scss">
.v-card__title{
  word-break: break-word;
}

@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Montserrat:wght@400;600;700;900&display=swap");

.times-cupon{
  font-size: 20px;
  text-shadow: 1px 1px 4px white;
  cursor: pointer;
}
.popover{
  z-index: 9;
  position: fixed;
  left: 10px;
  bottom: 10px;
}
.contact-text {
  font-size: 3vh;
}
.headline-text {
  font-size: 4vh;
}
.display-1-text {
  font-size: 4vh;
  @media(min-width: 992px){
    font-size: 6vh;
  }
}
strong{
  font-weight: bold !important;
  span{
    font-weight: bold !important;
  }
}
.display-2-text {
  font-size: 7vh;
}
.display-4-text {
  font-size: 10vmax;
}
.font-caption {
  font-size: 0.9vmax;
}
body,
* {
  font-family: "Cinzel", serif;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.semi-bold {
  font-weight: 600;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.black-text {
  font-weight: 900;
}
.font-size-auth {
  font-size: 12px !important;
}
.font-size-title {
  font-size: 6vmax !important;
}
.font-size-sub-title {
  font-size: 2vmax !important;
}
.font-size-title-phone {
  font-size: 1.8vmax !important;
}
.font-size-tolbart {
  font-size: 30px;
}
.font-size-card {
  font-size: 12px;
}
.font-size-footer {
  font-size: 15px;
  font-weight: 500;
}
.toolbar-hover:hover {
  background-color: #5ebd99;
  color: white;
}
.toolbar-hover:focus {
  background-color: #5ebd99;
  color: white;
}
.toolbar-hover-inicio:hover {
  color: #5ebd99;
}
.bg-brocha {
  background-image: url("assets/images/Grupo 320.svg");
}
.position-img .v-image__image {
  background-position: 20% 63% !important;
}
.position-img-admin .v-image__image {
  background-position: 20% 25% !important;
}
.mundo {
  background-color: white;
  height: 350px;
  padding-top: 5%;
  width: 280px;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
}
.text-gr {
  color: #5ebd99 !important;
}
.enlace-off{
  text-decoration: none;
  font-size: 13px;
  &:hover{
    text-decoration: underline;
  }
}
.text-center{
  text-align: center;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.link-dark{
  text-decoration: none;
  color: #000000;
}
.link-none{
  text-decoration: none;
}
</style>
