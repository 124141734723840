import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
};

const getters = {

};

const mutations = {

};

const actions = {
    async REGISTER(_ , payload ) {
        try {
            const response = await Api.post('/contacto', payload);            
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async INSCRIPCION(_, val){
        try{
            const response = await Api.post('/contacto_inscripcion', val)
            return response
        }catch(error){
            return Promise.reject(error);
        }
    },
    async CONTACTO(_, val){
        try{
            const response = await Api.post('/contacto_home', val)
            return response
        }catch(error){
            return Promise.reject(error);
        }
    }
    
    
};

export const contact = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
