import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
    section: []
};

const getters = {


    ITEMS_SECTION(state) {
        return state.section;
    },

};

const mutations = {

    SET_ITEM_SECTION(state, payload) {
        state.section = payload;
    },
};

const actions = {

    async INDEX( {commit} ) {
        try {
            const response = await Api.get(`/sections`);
            commit("SET_ITEM_SECTION", response.data.sections);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async SHOW(_ , payload ) {
        try {
            const response = await Api.get(`/sections/${payload.section_id}`);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async EVALUACIONES_REGISTER(_ , payload ) {
        try {
            const response = await Api.post('/evaluacion/', payload);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async SECTION_PAGO(_ , payload ) {
        try {
            const response = await Api.get(`/pago/${payload.id}`);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    
};

export const section = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
