import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
    isLoading: true,
    cursos: [],
    area: {},
    areas:[],
    subarea:{},
    courseRegister: '',
    custompagemenu: '',
    config: null,
    cursosList: [], 
    slider: [],
    comment: [],
    cupones: [],
    beneficios: [],
    cursos_activos:[],
    numberWhatsapp: {
        number:'',
        numberDisplay: ''
    },
    numberPhone: {
        number: '',
        numberDisplay: ''
    },
    social:[
        {name:'Facebook',  icon:'Facebook.svg',  link:'https://www.facebook.com/institutoidip'},
        {name:'Instagram', icon:'Instagram.svg', link:'https://www.instagram.com/cursoscortos/'},
        {name:'Twitter',   icon:'Twitter.svg',   link:'https://twitter.com/institutoidip'},
    ],
    subtipos: [],
    grupos: []
};

const getters = {
    titulo_uno(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'titulo_uno').description
      }
      return ''
    },
     titulo_dos(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'titulo_dos').description
      }
      return ''
    },
    phone(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'phone').description
      }
      return ''
    },
    whatsapp(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'whatsapp').description
      }
      return ''
    },
     direccion(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'direccion').description
      }
      return ''
    },
    mapUbication(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'mapa').description
      }
      return ''
    },
    gruposMenu(state){
        if (state.grupos.length > 0) {
            let grupos = state.grupos.filter((element)=>element.isMenu == 1)

            console.log(grupos)

            return grupos
        }
        return []
    },
    tcig(state){
        if(state.config != null){
            return state.config.find((element)=> element.element == 'tcig').description
        }
    },
    mensajeEfectivo(state){
      if (state.config != null) {
        return  state.config.find((element)=>element.element == 'mensajepagoefectivo').description
      }
      return ''
    },
    numbers(state){
        return [       
            { name: 'whatsapp', icon: 'whatsapp.svg', link: `https://api.whatsapp.com/send?phone=${state.numberWhatsapp.number}&text=Hola%20me%20gustaría%20saber%20sobre`, number: state.numberWhatsapp, numberDisplay: state.numberWhatsapp.numberDisplay  },
            { name: 'phone', icon: 'call_negro.svg', link: `tel:+${state.numberPhone.number}`, number: state.numberPhone, numberDisplay: state.numberPhone.numberDisplay }
        ]
    },
    logo(state){
        if (state.config != null) {
          return state.config.find((element)=> element.element == 'logo').description
        }
        return ''
    },
    banner_beneficios(){
        if (state.config != null) {
            return state.config.find((element)=>element.element == 'banner_beneficios').description
        }
        return ''
    },
    be_segunda(){
        if (state.config != null) {
            return state.config.find((element)=>element.element == 'be_segunda').description
        }
        return ''
    },
    body_area(){
        if (state.config != null) {
            return state.config.find((element)=>element.element == 'body_area').description
        }
        return ''
    },
    be_tercera(){
        if (state.config != null) {
            return state.config.find((element)=>element.element == 'be_tercera').description
        }
        return ''
    },
    arrayOfrecemos(){
        if (state.config != null) {
            return JSON.parse(state.config.find((element)=>element.element == 'loqueofrecemos').description)
        }
    },
    be_lista(){
        if (state.config != null) {
            return JSON.parse(state.config.find((element)=>element.element == 'be_lista').description)
        }
    },
    isFacebookLogin(state){
        if (state.config != null) {
          if(state.config.find((element)=> element.element == 'isfacebook').description == 1){
            return true
          }
        }
        return false
    },
    isGoogleLogin(state){
        if (state.config != null) {
          if(state.config.find((element)=> element.element == 'isgoogle').description == 1){
            return true
          }
        }
        return false
    },
    promocionados(state){
        var cursos = []
        state.cursos.filter((element)=>{
            if (element.promocionar) {
                cursos.push(element)
            }
        })
        return cursos
    },
    oficiales(state){
        var cursos = []
        state.cursos.filter((element)=>{
            if (element.tipo_curso.id == 2) {
               cursos.push(element) 
            }
        })
        return cursos
    },
    areasCursos(state){
        var array = state.areas
        array.map((element)=>{
            element.cursos = []
            state.cursosList.forEach((e)=>{
                if (element.id == e.area.id) {
                    element.cursos.push(e)
                }
            })
        })
        return array
    },
};

const mutations = {
    set_beneficios(state,val){
        state.beneficios = val
    },
    llenarCourseRegister(state, val){
        state.courseRegister = val
    },
    llenarNumeros(state, val){
        let nw = val.find((element)=> element.element == 'whatsapp').description
        let np = val.find((element)=> element.element == 'phone').description
        state.numberWhatsapp.numberDisplay = nw
        state.numberPhone.numberDisplay    = np
        state.numberWhatsapp.number = nw.replace(/[^0-9 ]/g, "")
        state.numberPhone.number = np.replace(/[^0-9 ]/g, "")
    },
    changeLoading(state, val){
        state.isLoading = val
    }
};

const actions = {
    async GET(context) {
        try {
            const response = await Api.get('/home'); 

            context.state.cursos         = response.data.cursos  
            context.state.areas          = response.data.areas
            context.state.slider         = response.data.slider    
            context.state.comment        = response.data.comment   
            context.state.subtipos       = response.data.subtipos
            context.state.cupones        = response.data.cupones
            context.state.custompagemenu = response.data.custompage
            context.state.config         = response.data.config
            context.state.cursos_activos = response.data.cursos_activos
            context.state.grupos         = response.data.grupos
            context.commit('llenarNumeros',response.data.config)
            context.commit('set_beneficios', response.data.beneficios)
            setTimeout(function(){
                context.commit('changeLoading', false)
            },1000)
            return
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async AREA(context, val){
        try{
            const response = await Api.get(`/area/${val.slug}`)
            context.state.area = response.data.area
            return
        }catch (error){
            return Promise.reject(error)
        }
    },
    async SUBAREA(context, val){
        try{
            const response = await Api.get(`/subarea/${val.area}/${val.slug}`)
            
            context.state.subarea = response.data.subarea
            return
        }catch (error){
            return Promise.reject(error)
        }
    },
    async CURSOS(context){
        try {
            const response = await Api.get('/courses');
            context.state.cursosList = response.data.cursos 
            return
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async PREGUNTAS(){
        try {
            const response = await Api.get('/preguntas');
            return response.data
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async UNIDADES_DOCUMENTS(context, val){
        try{
            const response = await Api.post('/document/public', val)
            return response.data
        }catch(error){ return Promise.reject(error)}
    },
    async SEARCHCERTIFICADO(context, val){
        try{
            const response = await Api.post('/searhCertificado', val)
            return response.data
        }catch(error){
            return Promise.reject(error)
        }
    },
    async CUSTOM_PAGE(context, val){
        try{
            const response = await Api.get('/custom-page?custompage='+val)
            return response.data
        }catch(error){
            return Promise.reject(error)
        }
    }
};

export const home = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
