import axios from 'axios';

const ApiFile = axios.create({
    baseURL: process.env.VUE_APP_ROOTAPI,
    headers: {
        "Content-Type": "multipart/form-data",
        // "Authorization": "Bearer " + localStorage.getItem("token")
    }
});

export default ApiFile;