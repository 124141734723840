import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './modules/auth'
import { section } from './modules/section'
import { course } from './modules/courses'
import { contact } from './modules/contact'
import { certificate } from './modules/certificate'
import { home } from './modules/home.js'
import { users } from './modules/users.js'
import { register } from './modules/register.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    URL_IMG: process.env.VUE_APP_ROOTIMG,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
	auth,
	section,
  course,
  contact,
  certificate,
  home,
  users,
  register
  }
})
