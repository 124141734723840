<template>
  <div id="footer">
    <v-footer padless>
      <v-card flat tile class="black--text"  width="100%">
        <v-card-text>
          
          <v-container>
            <v-row class="font-size-footer">
              <!-- MAPA -->
              <!-- <v-col cols="12" sm="12" md="2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.9766172717104!2d-58.38863488481375!3d-34.60475278045915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac4312e3301%3A0x1f0c894e0888c160!2s1er%20piso%2C%20Uruguay%20345%2C%20C1015ABG%20CABA%2C%20Argentina!5e0!3m2!1ses!2sve!4v1596822617602!5m2!1ses!2sve"
                  width="100%"
                  height="150"
                  frameborder="0"
                  style="border:0;"
                  allowfullscreen
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </v-col> -->
              <!-- DIRECCION -->
              <v-col cols="12" sm="12" md="4">
                <v-row>
                  <v-col cols="12" class="d-flex">
                    <p>
                      <v-avatar size="23" tile height="30" class="mr-2">
                        <v-img src="../../assets/images/house contacto.svg"></v-img>
                      </v-avatar>
                    </p>
                    <p>                    
                      Los cursos se dictan en : 
                      <v-spacer></v-spacer>
                      Uruguay 345 1er piso
                      Ciudad Autónoma de Buenos Aires a 3 cuadras del obelisco
                    </p>                      
                  </v-col>
                  <v-col cols="12" class="pt-0 d-flex font-weight-bold" v-for="(i, key) in numbers" :key="key">
                    <a :href="i.link" class="link-dark">
                      <v-avatar size="23" tile class="mr-2">
                        <v-img :src="require(`../../assets/img/${i.icon}`)"></v-img>
                      </v-avatar>{{ i.numberDisplay }}
                    </a>
                  </v-col>
                </v-row>
              </v-col>
              <!-- AREAS -->
              <v-col cols="12" sm="12" md="2">
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <router-link class="link-dark" to="/">Inicio</router-link>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-group :value="false" v-for="(i, key) in areas" :key="key">
                    <template v-slot:activator>
                      <v-list-item-title class="link-dark">{{ i.description }}</v-list-item-title>
                    </template>

                      <v-list-item v-for="(e, ke) in i.subareas" :key="ke">
                        <v-list-item-title class="px-4">
                           <router-link class="link-dark" :to="`/area/${i.slug}/${e.slug}`">{{ e.description }}</router-link>
                        </v-list-item-title>
                      </v-list-item>

                  </v-list-group>
                </v-list>
              </v-col>
              <!-- MENU FIJO -->
              <v-col cols="12" sm="12" md="2">
                <v-list>
                  <v-list-item class="px-0">
                    <v-list-item-title>
                      <router-link class="link-dark" to="/benefit">Beneficios</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-title>
                      <router-link class="link-dark" to="/inscripcion">Inscripción</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-title>
                      <router-link class="link-dark" to="/contact">Contacto</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-title>
                      <router-link class="link-dark" to="/ubication">¿Dónde estamos?</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-0">
                    <v-list-item-title>
                      <router-link class="link-dark" to="/frequent-questions">Preguntas frecuentes</router-link>
                    </v-list-item-title>
                  </v-list-item>
                  
                </v-list>
                <!-- <p>Términos y Condiciones</p> -->
                <!-- <p>Politica de privacidad y de cookies</p> -->
              </v-col>
              <!-- CUSTOM PAGE -->
              <v-col cols="12" sm="12" md="2">
                <v-list>
                  <v-list-item v-for="(e, key) in custompagemenu" :key="key" class="px-0">
                    <v-list-item-title v-if="e.is_menu_bottom == 1">
                      <router-link class="link-dark" :to="`/instituto/${e.slug}`">{{ e.title }}</router-link>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <!-- REDES SOCIALES -->
              <v-col cols="12" sm="12" md="2" class="text-center">
                <p>Siguenos</p>
                <p class=" text-center" v-for="(i, key) in social" :key="key">
                  <a :href="i.link"  target="_blank" class="">
                    <img :src="require(`../../assets/images/${i.icon}`)"  class="me-2" width="20" :alt="i.name">
                  </a>
                </p>
              </v-col>           
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text text-center d-none d-sm-flex" style="background: #302A2C">
          <v-img
            alt="Logo IDIP"
            class="shrink mr-2"
            contain
            src="../../assets/img/Logo_idip_blanco.svg"
            transition="scale-transition"
            width="80"
          />
          <p class="mt-4 ml-3">
            Derechos de autor © {{ new Date().getFullYear() }} IDIP
          </p>
          <v-spacer></v-spacer>
         <!--  <p class="mt-4">
            Condiciones Politica de Privacidad y Politica de Cookies
          </p> -->
          <!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong> -->
        </v-card-text>
        <v-card-text class="white--text text-center d-flex d-sm-none" style="background: #302A2C">
          <div align="center">
          <p>            
            <v-img
              alt="Logo IDIP"
              class="mr-2 text-center"
              contain
              src="../../assets/img/Logo_idip_blanco.svg"
              transition="scale-transition"
              width="80"
            />
          </p>
          <p class="mt-4 ml-3">
            Derechos de autor © {{ new Date().getFullYear() }} IDIP
          </p>
          <v-spacer></v-spacer>
          <!-- <p class="mt-4">
            Condiciones Politica de Privacidad y Politica de Cookies
          </p> -->
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
import {  mapState, mapGetters } from 'vuex'
export default {
  name: "Fotter",
  data: () => ({
    login_loading: false,
    drawer: false,
  }),
  watch: {
   
  },
  computed: {
    ...mapGetters('home',['numbers']),
    ...mapState('home',['areas','social','custompagemenu']),

  },
  methods: {
   
  },
};
</script>
<style lang="scss">
  #footer{
    .v-list{
     .link-dark, .v-icon{
      font-size: 14px;
      color:rgba(0, 0, 0, 0.6) !important;
     }
    }
  }
</style>