import Api from "@/services";
import ApiFile from "@/services/file";
  import toastr from 'toastr'
  const state = {
    token: null,
    authUser: {},
    login: false,
    name_login: '',
    user: {}
  };
  
  const getters = {
    AUTHENTICATED(state) {
      return state.token != null ? true : false;
    },
    AUTH_USER(state) {
      return state.authUser;
    },
    STATUS_LOGIN(state) {
      return state.login;
    },
    NAME_LOGIN(state) {
      return state.name_login;
    },
    USER() {
      return JSON.parse(localStorage.getItem('user'));
    }
  };
  
  const mutations = {
    CLEAR_DATA(state) {
      state.token = null;
      state.authUser = {};
    },
    /**
     * [SET_TOKEN description]
     * Asignar token solo en instancia axios
     * @param {[type]} state   [description]
     * @param {[type]} payload [description]
     */
    SET_TOKEN(state, payload) {
      Api.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    },
  
    /**
     * [description]
     * @param  {[type]} state   [description]
     * @param  {string} payload [description]
     * @return {[type]}         [description]
     */
    ADD_TOKEN: (state, payload) => {
      localStorage.setItem("token", payload);
      state.token = payload;
      Api.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
      ApiFile.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    },
  
    CLEAR_TOKEN: (state) => {
      localStorage.clear();
      state.token = null;
      delete Api.defaults.headers.common["Authorization"];
    },

    AUTH_USER: (state, payload) => {
      state.authUser = payload;
    },

    STATUS_LOGIN(state, payload) {
      state.login = payload.dialog_login
      state.name_login = payload.name_login
    },

    USER_DATA(state, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload
    }
  };
  
  const actions = {

    async LOGIN({ commit }, payload) {
      try {
        const response = await Api.post("/auth/login", payload);
        commit("ADD_TOKEN", response.data.token);
        commit("USER_DATA", response.data.user)
        return response;
      } catch (error) { 
        toastr.error(error.response.data.message) 
        commit("CLEAR_DATA")
        commit("CLEAR_TOKEN")
        return Promise.reject(error);
      }
    },

    async REGISTER({ commit }, payload) {
      try {
        const response = await Api.post("/auth/register", payload);
        commit("USER_DATA", response.data.user)
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async TRY_AUTO_LOGIN({ commit }) {
      try {
        const token = localStorage.getItem("token");
        const user = JSON.parse(localStorage.getItem("user"));
        // console.log("disparo la consulta")
        if (token === null) {
          return;
        } else {
          commit("ADD_TOKEN", token);
          commit("USER_DATA", user)
          // dispatch("GET_LOGIN_USER");
          return true;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // async GET_LOGIN_USER({ commit }) {
    //   try {
    //     const response = await Api.get("/auth/getuser");
    //     commit("AUTH_USER", response.data);
    //     return response;
    //   } catch (error) {
    //     return Promise.reject(error);
    //   }
    // },

    async LOGOUT({ commit }) {
      try {
        // const response = await Api.get("/auth/logout");
        commit("CLEAR_TOKEN");
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async PROFILE(_, payload) {
      try {
        const response = await ApiFile.post("/perfiles", payload);
        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async SHOW_PROFILE({state}) {
      try {
        const response = await Api.get("/perfiles/"+ JSON.parse(state.user).id);
        return response.data;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };
  
  // exportar propiedades
  export const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
  