import Api from "@/services";
import moment from 'moment'
/**
 * Modulo control de layout aplicacion
 */

const state = {
    user: {
        name: '',
        lastname: '',
        email: '',
        phone: '',
        dni: '',
        pais: '',
        domicilio: '',
        localidad: '',
        provincia: '',
    },
    estudiante_id: '',
    users_id: '',
    paso: 1,
    response: null,
    course: null,
    tipoinscripcion_id: null,
    fecha: null,
    cuotasInscritas: [],
    unidadesInscritas: [],
    cupon: null,
    cupon_id: null,
    inscripcion: null,
    inscripcionData: { id: null },
    cursoTotal: null
};

const getters = {
    courseName(state) {
        if (state.course) {
            // console.log(state.course.name)
            return state.course.name
        }
        return ''
    },
    totalidadDelCurso: (state, getters) => {
        return (state.cuotasInscritas.length * getters.tipo.montCuotas) - getters.tipo.descuento
    },
    tipoInscripciones(state) {
        var arr = []
        if (state.course) {
            state.course.tipo_inscripcion.forEach((element) => {
                if (element.tipo.length > 0) {
                    arr.push(element.tipo[0])
                }
            })
        }
        return arr
    },
    fechas(state) {
        var tipo = []
        if (state.course) {
            tipo = state.course.tipo_inscripcion.find((element) => element.tipo_inscripcions_id == state.tipoinscripcion_id)
            var arr = []
            if (tipo != undefined) {
                tipo.fechas.forEach((element) => {
                    if (element.cursos_id == state.course.id) {
                        arr.push(element)
                    }
                })

                tipo = arr
            } else {
                tipo = []
            }
        }
        return tipo
    },
    tipo: (state, getters) => {
        var tipo = []
        tipo = getters.tipoInscripciones.find((element) => element.id == state.tipoinscripcion_id)
        if (tipo == undefined) {
            tipo = []
        }
        return tipo
    },
    calculoPago: (state, getters) => {
        var suma = 0
        if (getters.tipo.tipo != 'cuotas') {
            state.unidadesInscritas.filter((element) => {
                suma += element.monto
            })
        } else {
            // console.log(state.cuotasInscritas)
            suma = state.cuotasInscritas.length * getters.tipo.montCuotas
            if (state.cursoTotal != null) {
                suma -= getters.tipo.descuento
            }
        }
        return suma
    },
    bonificado: (state, getters) => {
        if (state.course.bonificar == 1) {
            if (getters.segundosFinales < 0) {
                return false
            }
            return true
        }
    },
    segundosFinales() {
        if (state.course.tiempo_limite != null) {
            var yamismo = moment()
            var vence = moment(state.course.tiempo_limite)

            return vence.diff(yamismo)
        }
        return 1
    },
    totalApagar: (state, getters) => {
        var suma = 0
        suma += getters.calculoPago
        if (state.inscripcion && !getters.bonificado) {
            suma += state.course.precio_inscripcion
        }

        if (state.cupon != null) {
            // console.log(state.cupon)
            suma -= state.cupon.restar
        }
        return suma
    },
    calculoPagoDolares: (state, getters) => {
        var suma = 0
        if (getters.tipo.tipo != 'cuotas') {
            state.unidadesInscritas.filter((element) => {
                suma += element.monto_dolar
            })
        } else {
            suma = state.cuotasInscritas.length * getters.tipo.montCuotasDolar
            if (state.cursoTotal != null) {
                suma -= getters.tipo.descuentoDolar
            }
        }
        return suma
    },
    totalApagarDolares: (state, getters) => {
        var suma = 0

        suma += getters.calculoPagoDolares
        if (state.inscripcion && !getters.bonificado) {
            suma += state.course.precio_inscripcion_dolar
        }

        if (state.cupon != null) {
            suma -= state.cupon.restar_dolar
        }
        return suma
    },
    metodosPago(state) {
        if (state.course.metodos_pagos != null || state.course.metodos_pagos != '') {
            return JSON.parse(state.course.metodos_pagos)
        }
        return []
    },
    //DUPLICADO 
    montoTotalCuotas: (state, getters) => {
        return (getters.tipo.cantCuotas * getters.tipo.montCuotas) - getters.tipo.descuento
    }
};

const mutations = {
    SET_INSCRIPCION(state, val) {
        state.inscripcionData.id = val
    },
    SET_USER(state, val) {
        state.user = val
    },
    CAMBIO_PASO(state, val) {
        state.paso = val
    },
    SET_RESPONSE(state, val) {
        state.response = val
    },
    SET_COURSE(state, val) {
        state.course = val
    },
    SET_TIPOINSCRIPCION_ID(state, val) {
        state.tipoinscripcion_id = val
    },
    SET_FECHA_ID(state, val) {
        state.fecha = val
    },
    SET_CUOTAS(state, val) {
        state.cuotasInscritas = val
    },
    SET_UNIDADES(state, val) {
        state.unidadesInscritas = val
    },
    SET_CUPON(state, val) {
        state.cupon = val
    },
    SET_DATA(state) {
        state.user = {
            name: '',
            lastname: '',
            email: '',
            phone: '',
            dni: '',
        }
        state.estudiante_id = ''
        state.users_id = ''
        state.paso = 1
        state.response = null
        state.course = null
        state.tipoinscripcion_id = null
        state.fecha = null
        state.cuotasInscritas = []
        state.unidadesInscritas = []
        state.cupon = null
        state.cupon_id = null
    },
    SET_PAGO_INSCRIPCION(state, val) {
        state.inscripcion = val
    },
    SET_CURSOTOTAL(state, val) {
        state.cursoTotal = val
    }
};


const actions = {
    async REGISTERCOURSE(context, val) {
        try {
            const response = await Api.get(`auth/registercourse/${val.areas_id}/${val.slug}/${val.users_id}`)
            context.commit('SET_RESPONSE', response.data)
            context.commit('SET_COURSE', response.data.curso)
            context.commit('SET_PAGO_INSCRIPCION', response.data.inscripcion)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async ADDSALA(context) {
        try {
            var obj = {
                name: context.state.user.name,
                lastname: context.state.user.lastname,
                email: context.state.user.email,
                phone: context.state.user.phone,
                dni: context.state.user.dni,
                cursos_id: context.state.course.id,
                tipoinscripcion_id: context.state.tipoinscripcion_id,
                fecha: context.state.fecha,
                users_id: context.rootState.auth.user.id,
            }
            const response = await Api.post(`auth/add-sala`, obj)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async CONSULTA_CUPON(context, data) {
        try {
            const response = await Api.post(`auth/consulta/cupon`, data)
            if (response.data.status) {
                context.state.cupon_id = response.data.cupon_id
            }
            return response
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async INSCRIPCION(context) {
        try {
            var obj = {
                name:               context.state.user.name,
                lastname:           context.state.user.lastname,
                email:              context.state.user.email,
                phone:              context.state.user.phone,
                dni:                context.state.user.dni,
                pais:               context.state.user.pais,
                domicilio:          context.state.user.domicilio,
                localidad:          context.state.user.localidad,
                provincia:          context.state.user.provincia,
                cursos_id:          context.state.course.id,
                tipoinscripcion_id: context.state.tipoinscripcion_id,
                fecha:              context.state.fecha,
                users_id:           context.rootState.auth.user.id,
                unidadesInsctitas:  context.state.unidadesInscritas,
            }
            if (context.getters.tipo.tipo == 'cuotas') {
                obj.cuotas = context.state.cuotasInscritas.length
            }
            if (context.state.cupon) {
                obj.cupon_id = context.state.cupon_id
            }
            const response = await Api.post(`auth/inscripcion-curso`, obj)
            return response

        } catch (error) {
            return Promise.reject(error)
        }
    },
    async GET_CREATE_PREFERENCE_MP(context, val) {
        try {
            const response = await Api.post('auth/getCreatePreferenciaMP', val);
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async ACTIVATE_INSCRIPCION(context, val) {
        try {
            const response = await Api.post(`auth/activate-inscripcion`, val)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async ACTIVATE_UNIDAD(context, val) {
        try {
            const response = await Api.post(`auth/activate-unidad`, val)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async DELETE_INSCRIPCION(context, val) {
        try {
            const response = await Api.post(`auth/delete-inscripcion`, val)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async SOLICITUDES_UNIDADES(context, val){
        try {
            const response = await Api.post(`auth/solicitudes-unidades`, val)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

};

export const register = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};