import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/veeValidate/veeValidate";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";
import VueCookies 
from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('7d')


Vue.use(VueGtag, {
  config: { id: "UA-79758832-1" }
},router);

Vue.use(VueMeta)


library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;
store.dispatch("auth/TRY_AUTO_LOGIN");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
