import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import VueMeta from 'vue-meta'


Vue.use(VueRouter);
Vue.use(VueMeta,{
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/Principal.vue"),
  },
  {
    path: "/:area/:area_id/course/:slug",
    name: "course",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/DetailCourse.vue"),
  },
  {
    path: "/course/preview/:area/:area_id/:slug/:preview",
    name: "course",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/DetailCourse.vue"),
  },
  {
    path: "/area/:area/:slug",
    name: "area",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/BodyArea.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Procesar/Register.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Procesar/Login.vue"),
  },
  {
    path: "/courses-list",
    name: "courseslist",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/CoursesList.vue"),
  },
  {
    path: "/documentos/public",
    name: "documentos_publicos",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/DocumentPublic.vue"),
  },
  {
    path: "/inscripcion",
    name: "inscripcion",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/Home/Inscripcion.vue"),
  },
  {
    path: "/ubication",
    name: "ubication",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/Ubication.vue"),
  },
  {
    path: "/frequent-questions",
    name: "questions",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/Questions.vue"),
  },
  {
    path: "/search-certificado",
    name: "searchCertificado",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/searhCertificado.vue"),
  },
  {
    path: "/instituto/:custompage",
    name: "customPage",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/customPage.vue"),
  },
  {
    path: "/certificado/:dni/:tipo/:id",
    name: "certificado",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Certificate.vue"),
  },
  {
    path: "/benefit",
    name: "benefit",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Home/Benefit.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Home/Contact.vue"),
  },
  {
    path: "/register-course",
    name: "register-course",
    beforeEnter: (to, from, next) => {
      const authUser = store.getters["auth/AUTHENTICATED"];
      if (authUser) {
        next();
      } else {
        var url = '/login'
        if (from.name == 'course') {
          url = '/register?course='+from.params.slug+'&area_id='+from.params.area_id
        }
        next({
          path: url,
        });
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/User/RegisterCourse.vue"),
  },
  {
    path: "/register-unidades",
    name: "register-unidades",
    beforeEnter: (to, from, next) => {
      const authUser = store.getters["auth/AUTHENTICATED"];
      if (authUser) {
        next();
      } else {
        var url = '/login'
        if (from.name == 'course') {
          url = '/login?course='+from.params.slug+'&area_id='+from.params.area_id
        }
        next({
          path: url,
        });
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/User/RegisterUnidades.vue"),
  },

  {
    path: "/user/home",
    name: "userwelcome",
    beforeEnter: (to, from, next) => {
      const authUser = store.getters["auth/AUTHENTICATED"];
      if (authUser) {
        next();
      } else {
        next({
          path: "/login",
        });
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/User/Home.vue"),
  },
  {
    path: "/user/course/:area_id/:slug",
    name: "usercourse",
    beforeEnter: (to, from, next) => {
      const authUser = store.getters["auth/AUTHENTICATED"];
      if (authUser) {
        next();
      } else {
        next({
          path: "/login",
        });
      }
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/User/Course.vue"),
  },
  {
    path: "/success/:curso_id/:users_id/:inscripcion_id",
    name: "successPayment",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/payment/successPayment.vue"),
  },
  {
    path: "/failure/:curso_id/:users_id/:inscripcion_id",
    name: "successPayment",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/payment/failurePayment.vue"),
  },
  

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// PROTECCION DE RUTAS
router.beforeEach((to, from, next) => {
  // CONSULTA USUARIO LOGUEADO
  const authUser = store.getters["auth/AUTHENTICATED"];
  // console.log("*****", authUser);

  // CREACION META
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authUser) {
      console.log("Autenticado", authUser);
      next({
        path: "/",
      });
    }
  } else {
    next();
  }
});

export default router;
