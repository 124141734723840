import Api from "@/services";

/**
 * Modulo control de layout aplicacion
 */

const state = {
};

const getters = {

};

const mutations = {

};

const actions = {
    async SHOW(_ , obj ) {
        try {
            const response = await Api.get(`certificado/${obj.dni}/${obj.tipo}/${obj.certificadoid}`);            
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    
};

export const certificate = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
